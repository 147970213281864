import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const SupportComponent = () => {
  const [iframeUrl, setIframeUrl] = useState('');
  const env =
    import.meta.env.VITE_ENVIRONMENT === 'UAT'
      ? '-uat'
      : import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION'
      ? ''
      : '-dev';

  useEffect(() => {
    const queryParams = new URLSearchParams({
      solution: '',
    }).toString();
    const url = `https://support-center${env}.edaa.siemens-energy.cloud/Home?${queryParams}`;
    setIframeUrl(url);
  }, []);

  return (
    <Box className="iframe-container" sx={{ height: '80vh' }}>
      <iframe
        title="Support App"
        style={{ height: '100%', width: '100%' }}
        src={iframeUrl}
        frameBorder="0"
      ></iframe>
    </Box>
  );
};

export default SupportComponent;
