import { Button, Logo, Stack } from '@data-products-and-ai/react-components';
import AzureLoginButton from './components/AzureLoginButton';
import Background from './assets/background.jpg';
import React from 'react';

const Login = () => {
  const handleEmail = () => {
    const mail = document.createElement('a');
    mail.href = `mailto:sam.chandra@siemens-energy.com?subject=Snowflake Ingestor - Request access`;
    mail.click();
    mail.remove();
  };

  const handleTicket = () => {
    window.open(
      'https://support-center-dev.edaa.siemens-energy.cloud/Home',
    );
  };

  return (
    <div
      className="loginContainer"
      style={
        {
          '--bg-image': `url(${Background})`,
        } as React.CSSProperties
      }
    >
      <div className="loginFormContainer">
        <Logo background="light" size="medium" />
        Parts Proactive Sales

        <div style={{ marginTop: 25 }}>
          <AzureLoginButton />
        </div>

        <div className="loginFormAdditional">
          <hr />

          <Stack direction="row" horizontalAlign="center">
            <Button type="link" onClick={handleEmail} allCaps={false}>
              Request Access
            </Button>
            <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>

            <Button type="link" onClick={handleTicket} allCaps={false}>
              Support
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Login;
