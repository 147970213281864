import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';

import {
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import AzureLogo from '../assets/azure_white.png';


const handleLogin = (msalInstance: IPublicClientApplication) => async () => {
  await msalInstance.loginRedirect();
};


const LoginButton = () => {
  const [errorMessage] = useState(null);
  const { instance } = useMsal();
  return (
    <>
      <button className="loginButton" onClick={handleLogin(instance)}>
        <Stack direction="row" horizontalAlign="center">
          <img src={AzureLogo} height={20} style={{ marginRight: 10 }} alt="" />
          <Typography tag="textdefault">Login with Azure AD</Typography>
        </Stack>
      </button>

      {errorMessage && (
        <div className="error-message" style={{ color: 'red', marginTop: 20 }}>
          {errorMessage}
        </div>
      )}
    </>
  );
};

export default LoginButton;
