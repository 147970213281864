import React from 'react';

const HomePage: React.FC = () => {

  return (
    <div className="homePage">
      <div className="homePage-grid">
        <div className="bucketExplorer-container">
          <div className="bucketExplorer-templateList">         
          </div>
          <div className="bucketExplorer-fileList">
            This is the homepage, only accessible to authenticated users.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
